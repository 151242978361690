const styles = {
  buttonHolder: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },

  holder: {
    width: "100%"
  },
};
export default styles;
