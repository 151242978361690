import { useDispatch } from 'react-redux';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from '../../entity-editor/EntityEditor.styles';
import PostcodeForm from '../../postcode-form/PostcodeForm';
import StyledTextField from '../../styled-components/StyledTextField';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const initialEditModeState = {
  isEdit: false,
  index: -1
};
const initialUnitState = {
  size: '',
  built_year: '',
  price: '',
  purpose: '',
  bug_id: '',
  bug_url: '',
  address: {
    street_name: '',
    number: '',
    number_add: '',
    postcode: ''
  }
};

const initialUnitDetails = {
  size: '',
  built_year: '',
  price: '',
  purpose: '',
  bug_id: '',
  bug_url: '',
  address: {
    street_name: '',
    number: '',
    number_add: '',
    postcode: ''
  },
  index: -1,
  id: '',
  modified: false
};

const ObjectUnitsBlock = ({ units, onChange }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(initialEditModeState);
  const [objectUnit, setObjectUnit] = useState(initialUnitState);
  const [objectUnitsList, setObjectUnitsList] = useState([]);
  const [editedUnitDetails, setEditedUnitDetails] =
    useState(initialUnitDetails);
  //TODO check in next development iteration do we need this variable and code lines 462-469

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unitForDelete, setUnitForDelete] = useState([]);
  console.log('unitForDelete ', unitForDelete);

  useEffect(() => {
    setObjectUnitsList(units);
  }, [dispatch, units]);

  const handleClearForm = () => {
    /** clear all fields and error statuses **/
    setEditedUnitDetails(initialUnitDetails);
    setObjectUnit(initialUnitState);
    setEditMode(initialEditModeState);
    setUnitForDelete([]);
    setObjectUnitsList([]);
  };

  const handleChange = (
    name,
    key,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setObjectUnit({
      ...objectUnit,
      [key]: event.target.value
    });
    if (name === 'objectUnitAddress') {
      return setObjectUnit({
        ...objectUnit,
        address: {
          ...objectUnit.address,
          [key]: event.target.value
        }
      });
    }
  };

  const handleChangeItem = (fieldName, selectedIndex, event) => {
    setEditedUnitDetails({
      ...editedUnitDetails,
      [fieldName]: event.target.value,
      index: selectedIndex,
      modified: true
    });
  };

  const handleDeleteItem = (selectedIndex) => {
    const newStateForDelete = [];
    objectUnitsList.map((item: any, index) => {
      if (index === selectedIndex && item.id) {
        // @ts-ignore
        return newStateForDelete.push(item);
      } else {
        return item;
      }
    });

    const filteredList = objectUnitsList.filter(
      (item: any, index) => index !== selectedIndex
    );

    const newState = {
      filteredList,
      newStateForDelete
    };
    console.log('newStateForDelete ', newStateForDelete)
    setObjectUnitsList(filteredList);
    setUnitForDelete(newStateForDelete);
    onChange('objectUnits', newState, true);
  };

  const handleSetEditMode = (type, selectedIndex) => {
    /** managing open/close state for ip item change, save changes by click in ip list **/
    if (type === 'open') {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      objectUnitsList.forEach((item: any, index) => {
        if (index === selectedIndex) {
          setEditedUnitDetails({
            ...item,
            index: selectedIndex,
            // id: item.id || null,
            size: item.size,
            built_year: item.built_year,
            price: item.price,
            purpose: item.price,
            bug_id: item.bug_id,
            bug_url: item.bug_url,
            address: item.address
          });
        }
      });
    }
    if (type === 'close') {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
    }
    if (type === 'edit') {
      const newState = objectUnitsList.map((item: any, index) => {
        if (index === editedUnitDetails.index && index === selectedIndex) {
          return {
            // id: editedUnitDetails.id,
            size: editedUnitDetails.size,
            built_year: editedUnitDetails.built_year,
            price: editedUnitDetails.price,
            purpose: editedUnitDetails.purpose,
            bug_id: editedUnitDetails.bug_id,
            bug_url: editedUnitDetails.bug_url,
            address: editedUnitDetails.address
          };
        } else {
          return item;
        }
      });
      // @ts-ignore
      setObjectUnitsList(newState);
      onChange('objectUnits', newState);
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      setEditedUnitDetails(editedUnitDetails);
    }
  };

  const addNewUnit = () => {
    const newState = [
      ...objectUnitsList,
      {
        size: objectUnit.size,
        built_year: objectUnit.built_year,
        price: objectUnit.price,
        purpose: objectUnit.purpose,
        bug_id: objectUnit.bug_id,
        bug_url: objectUnit.bug_url,
        address: objectUnit.address
      }
    ];
    // @ts-ignore
    setObjectUnitsList(newState);
    onChange('objectUnits', newState);
    handleClearForm();
  };

  const getUnitName = (item) => {
    let unitPostcodePart = '';
    let unitStreetPart = '';
    let unitNumberPart = '';
    let unitNumberAddPart = '';
    let unitPurposePart = '';
    let unitBuiltYearPart = '';
    let unitSizePart = '';

    if (item.address && item.address.postcode && item.address.postcode !== '') {
      unitPostcodePart = `${item.address.postcode}, `;
    }
    if (
      item.address &&
      item.address.street_name &&
      item.address.street_name !== ''
    ) {
      unitStreetPart = `${item.address.street_name}, `;
    }
    if (item.address && item.address.number && item.address.number !== '') {
      unitNumberPart = `${item.address.number}, `;
    }
    if (
      item.address &&
      item.address.number_add &&
      item.address.number_add !== ''
    ) {
      unitNumberAddPart = `${item.address.number_add}, `;
    }
    if (item.purpose && item.purpose !== '') {
      unitPurposePart = `purpose: ${item.purpose}, `;
    }
    if (item.built_year && item.built_year !== '') {
      unitBuiltYearPart = `year: ${item.built_year}, `;
    }
    if (item.size && item.size !== '') {
      unitSizePart = `size ${item.size} m2, `;
    }

    return (
      unitPostcodePart +
      unitStreetPart +
      unitNumberPart +
      unitNumberAddPart +
      unitPurposePart +
      unitBuiltYearPart +
      unitSizePart
    );
  };

  return (
    <Accordion sx={{ mb: '1rem' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          Units
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" sx={styles.textField}>
          Unit address
        </Typography>
        <PostcodeForm />
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Street"
              margin="dense"
              onChange={(e) =>
                handleChange('objectUnitAddress', 'street_name', e)
              }
              value={objectUnit.address.street_name}
            />
            <StyledTextField
              fullWidth
              label="Number"
              margin="dense"
              onChange={(e) => handleChange('objectUnitAddress', 'number', e)}
              value={objectUnit.address.number}
            />
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Number add"
              margin="dense"
              onChange={(e) =>
                handleChange('objectUnitAddress', 'number_add', e)
              }
              value={objectUnit.address.number_add}
            />
            <StyledTextField
              fullWidth
              label="Postcode"
              margin="dense"
              onChange={(e) => handleChange('objectUnitAddress', 'postcode', e)}
              value={objectUnit.address.postcode}
            />
          </Box>
        </Box>
        <Divider sx={{ marginTop: '15px', marginBottom: '15px' }} />
        <Typography variant="body2" sx={styles.textField}>
          General units fields
        </Typography>
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Size"
              margin="dense"
              onChange={(e) => handleChange('objectUnits', 'size', e)}
              value={objectUnit.size}
            />
            <StyledTextField
              fullWidth
              label="Built year"
              margin="dense"
              onChange={(e) => handleChange('objectUnits', 'built_year', e)}
              value={objectUnit.built_year}
            />
            <StyledTextField
              fullWidth
              label="Price"
              margin="dense"
              onChange={(e) => handleChange('objectUnits', 'price', e)}
              value={objectUnit.price}
            />
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Purpose"
              margin="dense"
              onChange={(e) => handleChange('objectUnits', 'purpose', e)}
              value={objectUnit.purpose}
            />
            <StyledTextField
              fullWidth
              label="BUG ID"
              margin="dense"
              onChange={(e) => handleChange('objectUnits', 'bug_id', e)}
              value={objectUnit.bug_id}
              disabled
            />
            <StyledTextField
              fullWidth
              label="BUG URL"
              margin="dense"
              onChange={(e) => handleChange('objectUnits', 'bug_url', e)}
              value={objectUnit.bug_url}
              disabled
            />
          </Box>
        </Box>
        <Button sx={styles.button} onClick={addNewUnit}>
          Add new unit
        </Button>
        <Divider sx={{ marginTop: '15px', marginBottom: '15px' }} />
        {objectUnitsList?.length > 0 &&
          objectUnitsList.map(
            (
              item: {
                address: {
                  street: string;
                  number: string;
                  number_add: string;
                  postcode: string;
                };
                size: string;
                built_year: string;
                price: string;
                purpose: string;
                bug_id: string;
                bug_url: string;
              },
              index
            ) => {
              return editMode.isEdit && editMode.index === index ? (
                <Box sx={styles.editHolder} key={index}>
                  <Typography variant="body2" sx={styles.textField}>
                    Edit current unit
                  </Typography>
                  <Box sx={styles.editFieldsBlockHolder}>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Size"
                        margin="dense"
                        onChange={(e) => handleChangeItem('size', index, e)}
                        value={editedUnitDetails.size}
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Built year"
                        margin="dense"
                        onChange={(e) =>
                          handleChangeItem('built_year', index, e)
                        }
                        value={editedUnitDetails.built_year}
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Price"
                        margin="dense"
                        onChange={(e) => handleChangeItem('price', index, e)}
                        value={editedUnitDetails.price}
                      />
                    </Box>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Purpose"
                        margin="dense"
                        onChange={(e) => handleChangeItem('purpose', index, e)}
                        value={editedUnitDetails.purpose}
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        label="BUG ID"
                        margin="dense"
                        onChange={(e) => handleChangeItem('bug_id', index, e)}
                        value={editedUnitDetails.bug_id}
                        disabled
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        label="BUG URL"
                        margin="dense"
                        onChange={(e) => handleChangeItem('bug_url', index, e)}
                        value={editedUnitDetails.bug_url}
                        disabled
                      />
                    </Box>
                  </Box>
                  <Box sx={styles.editButtonGroup}>
                    <IconButton
                      onClick={() => handleSetEditMode('edit', index)}
                    >
                      <DoneIcon fontSize={'small'} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleSetEditMode('close', index)}
                    >
                      <CloseIcon fontSize={'small'} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Box sx={styles.itemContainer} key={index}>
                  <Box sx={styles.itemHolder}>
                    <Box sx={styles.infoHolder}>
                      <Box sx={styles.info}>
                        <Typography sx={styles.itemDetails} variant={'h6'}>
                          Unit:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={'h6'}>
                          {/*{*/}
                          {/*  `${item.address.postcode ? item.address.postcode : ''} ${item.address.street ? item.address.street : ''} `*/}
                          {/*}*/}
                          {getUnitName(item)}
                          {/*{`${item.address.postcode}, ${item.address.street} ${item.address.number} ${item.address.number_add}, ${item.purpose}, ${item.built_year}, ${item.size}`}*/}
                          {/*{` ${item.purpose}, ${item.built_year}, ${item.size}`}*/}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.iconHolder}>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => handleSetEditMode('open', index)}
                      >
                        <EditIcon sx={{ color: '#114B76CC' }} />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleDeleteItem(index)}
                      >
                        <DeleteIcon sx={{ color: '#114B76CC' }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            }
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ObjectUnitsBlock;
