import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from "./Tabs.styles";
import { Paper } from '@mui/material';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
}

const tabProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={styles.tabPanel}>{children}</Box>}
    </div>
  );
};

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    variant="fullWidth"
    indicatorColor="secondary"
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    marginBottom: 5,
  },
  "& .MuiTabs-indicatorSpan": {
    width: "95%",
    backgroundColor: "#fff",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab centerRipple {...props} />
))(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.secondary.light,
  "&.Mui-selected": {
    color: "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.secondary.light,
  },
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    fontSize: theme.typography.pxToRem(12),
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.pxToRem(14),
  },
  [theme.breakpoints.up("md")]: {
    padding: "18px 16px",
    fontSize: theme.typography.pxToRem(16),
  },
}));

const CustomTabs = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Paper sx={styles.contentHolder}>
      <AppBar position="relative" sx={styles.dataBox}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="custom tabs"
        >
          {props.children.map((item: { type: string; content: any }, index) => (
            <StyledTab key={index} label={item.type} {...tabProps(index)} />
          ))}
        </StyledTabs>
      </AppBar>
      <SwipeableViews
        axis={"direction" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {props.children.map((item: { type: string; content: any }, index) => (
          <TabPanel value={value} index={index} key={index}>
            {item.content}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Paper>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default CustomTabs;
