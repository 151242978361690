import { call, put, takeLatest } from "redux-saga/effects";
import {
  DELETE_OBJECT_REQUEST,
  GET_OBJECT_BY_ID_REQUEST,
  GET_OBJECTS_BY_OWNER_REQUEST,
  UPDATE_OBJECT_REQUEST,
} from "../../../common/constants/action-constants";
import ApiService from "../../../common/services/api.service";
import {
  receiveObjectByIdSuccess,
  receiveObjectsByOwnerError,
  receiveObjectsByOwnerSuccess,
  removeObjectSuccess,
  removeObjectError,
  updateObjectError,
  updateObjectSuccess,
  receiveObjectsByOwnerRequest,
} from '../actions/objects';
import { receiveObjectsStakeholdersRequest } from "../actions/objectsStakeholders";
import AlertService from "../../../common/services/notification.service";
import { notificationMessage } from "../../../common/constants/notication-constants";

const LIMIT = 100;

function* getObjectByOwnerList(action: any) {
  try {
    const objects = yield call(ApiService.post, "/object/by_owner", {
      id: action.id,
      index: 0,
      limit: LIMIT,
    });
    if (objects) {
      yield put(receiveObjectsByOwnerSuccess(objects.data));
      const objectsIds = objects.data.map((object: any) => object.object_id);
      yield put(receiveObjectsStakeholdersRequest(objectsIds));
    }
  } catch (e) {
    yield put(receiveObjectsByOwnerError(e));
  }
}

function* getObjectById(action: any) {
  try {
    const object = yield call(ApiService.get, `/object/data/${action.id}`);
    console.log('object', object);
    if (object) {
      yield put(receiveObjectByIdSuccess(object.data));
    }
  } catch (e) {
    yield put(receiveObjectsByOwnerError(e));
  }
}

function* deleteObject(action: any) {
  try {
    const objectDelete = yield call(ApiService.delete, `/object/${action.id}`);
    if (objectDelete && objectDelete.data.message) {
      yield put(removeObjectSuccess(objectDelete.data.message));
    }
  } catch (error) {
    yield put(removeObjectError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* updateObject(action) {
  try {
    const updatedObject = yield call(
      ApiService.put,
      `/object/${action.objectId}`,
      {
        types: action.actionTypes,
        data: action.data,
      }
    );
    if (updatedObject) {
      yield put(
        updateObjectSuccess(
          updatedObject.data,
          "Object was successfully updated!"
        )
      );
      if(action.entityId !== null) {
        yield put(receiveObjectsByOwnerRequest(action.entityId));
      }
    }
  } catch (error) {
    yield put(updateObjectError(error));
  }
}

export default function* actionObjects() {
  yield takeLatest(GET_OBJECTS_BY_OWNER_REQUEST, getObjectByOwnerList);
  yield takeLatest(GET_OBJECT_BY_ID_REQUEST, getObjectById);
  yield takeLatest(DELETE_OBJECT_REQUEST, deleteObject);
  yield takeLatest(UPDATE_OBJECT_REQUEST, updateObject);
}
