const styles = {
  intervalInput: {
    display: "flex",
    justifyContent: "right",
    padding: "0 22px 15px 0",
  },

};

export default styles;
