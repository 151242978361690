import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  createNewEntitySuccess,
  createNewObjectSuccess,
  createNewEntityError,
  createNewObjectError,
  receivePostcodeError,
  receivePostcodeSuccess,
} from "../actions";
import {
  CREATE_NEW_ENTITY_REQUEST,
  CREATE_NEW_OBJECT_REQUEST,
  RECEIVE_POSTCODE_REQUEST
} from '../../../common/constants/action-constants';
import AlertService from '../../../common/services/notification.service';

function* createNewEntity(action) {
  try {
    const newEntity = yield call(ApiService.post, "/entity", action.entity);
    yield put(createNewEntitySuccess(newEntity.data));
    action.navigate(`/leadgen/entity/${newEntity.data.id}`);
  } catch (error) {
    yield put(createNewEntityError(error));
  }
}

function* createNewObject(action) {
  try {
    const newObject = yield call(ApiService.post, "/object", action.object);
    yield put(createNewObjectSuccess(newObject));
    action.navigate(`/leadgen/object/${newObject.data.id}`);
  } catch (error) {
    yield put(createNewObjectError(error));
  }
}

function* receivePostcode(action) {
  try {
    const result = yield call(
      ApiService.post,
      "/search/postcode",
      action.requestData
    );
    if (result) {
      if (result.data.transformData?.length > 0) {
        yield put(receivePostcodeSuccess(result.data.transformData));
        AlertService.success(result.data.message)
      } else {
        AlertService.error(result.data.message);
      }
    }

  } catch (error) {
    yield put(receivePostcodeError(error));
  }
}

export default function* actionNewEntity() {
  yield takeLatest(CREATE_NEW_ENTITY_REQUEST, createNewEntity);
  yield takeLatest(CREATE_NEW_OBJECT_REQUEST, createNewObject);
  yield takeLatest(RECEIVE_POSTCODE_REQUEST, receivePostcode)
}
