const styles = {
  root: {
    width: "100%",
  },
  textField: {
    color: "#495057",
  },
  fieldHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  fieldBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "49%",
  },
  editHolder: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  editFieldsBlockHolder: {
    display: "flex",
    justifyContent: "space-between",
    width: "inherit",
  },
  editButtonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  editAddressFields: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  itemHolder: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "10px 4px",
  },
  itemDetails: {
    fontSize: "1.2rem",
    marginRight: "15px",
    color: "#495057",
  },
  button: {
    display: "flex",
    width: "100%",
    margin: "10px auto 10px",
    color: "rgb(17,75,118)",
    "&:hover": {
      backgroundColor: "rgba(17,75,118,0.1)",
    },
  },
  infoHolder: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
  },
  iconHolder: {
    display: "flex",
    justifyContent: "space-between",
    width: "5%",
  },
  typeInfo: {
    display: "flex",
    width: "20%",
  },
  info: {
    display: "flex",
    width: "80%",
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },

};

export default styles;
