import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../EntityEditor.styles";
import React, { useState } from "react";
import StyledTextField from "../../styled-components/StyledTextField";

const EntityGDriveBlock = ({ driveLink, onChange }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [entityGDrive, setEntityGDrive] = useState("");
  return (
    <Accordion sx={{ mb: '1rem' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          Google drive
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.fieldHolder}>
          <StyledTextField
            fullWidth
            label="Google drive link"
            margin="dense"
            // onChange={(e) => handleChange("entitySocials", "social", e)}
            // value={entitySocial.social}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default EntityGDriveBlock;
