const styles = {
  defaultButton: {
    minWidth: "40px",
    background: "rgba(4, 98, 54, 0.8)",
    textAlign: "center",
    color: "#B4CEE5",
  },

  buttonSave: {
    width: "110px",
    height: "40px",
    marginRight: "10px",
    background: "rgba(4, 98, 54, 0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover, &:active": {
      backgroundColor: "rgba(4, 98, 54, 0.9)",
      borderColor: "#046236",
      boxShadow: "none",
    },
  },

  buttonEdit: {
    maxWidth: '95px',
    width: '100%',
    height: '34px',
    background: "rgba(4, 98, 54, 0.8)",
  },

  buttonDelete: {
    width: "110px",
    height: "40px",
    marginRight: "10px",
    background: "#D67575",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover, &:active": {
      backgroundColor: "#d01f1f",
      borderColor: "#e53a3a",
      boxShadow: "none",
    },
  },

  buttonDeleteLeadgen: {
    marginRight: 0,
    height: '34px',
    maxWidth: '95px',
    width: '100%',
    background: "#D67575",
  },

  buttonCancel: {
    width: "110px",
    height: "40px",
    background: "rgba(17,75,118,0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover, &:active": {
      backgroundColor: "rgba(17,75,118,0.9)",
      borderColor: "#114B76",
      boxShadow: "none",
    },
  },

  buttonCancelLeadgen: {
    width: '95px',
    height: '34px',
    background: "rgba(17,75,118,0.8)",
  },

  buttonEditObjects: {
    maxWidth: {
      xs: '6rem',
      sm:'7.5rem',
      xl:'8rem'
    },
    width: '100%',
    height: '34px',
    background: 'rgba(4, 98, 54, 0.8)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '1.25em',
    fontSize: {
      xs: '10px',
      sm: '12px',
      lg: '13px',
      xl: '14px',
    },
    marginRight: {
      xs: '0.5rem',
      md:'1rem',
      xl:'1.2rem',
    },
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(4, 98, 54, 0.9)',
      borderColor: '#046236',
      boxShadow: 'none'
    }
  },

  buttonDeleteObjects: {
    maxWidth: {
      xs: '6rem',
      sm:'7.5rem',
      xl:'8rem'
    },
    width: '100%',
    height: '34px',
    background: '#D67575',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '1.25em',
    fontSize: {
      xs: '10px',
      sm: '12px',
      lg: '13px',
      xl: '14px',
    },
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#d01f1f',
      borderColor: '#e53a3a',
      boxShadow: 'none'
    }
  },

};

export default styles;
