import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../../entity-editor/EntityEditor.styles";
import StyledTextField from "../../styled-components/StyledTextField";
import PostcodeForm from "../../postcode-form/PostcodeForm";

const ObjectAddressBlock = ({ onChange, objectFields, errors, selectAddressObject, unitsLength }) => {
  const type='object'
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          Address *
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PostcodeForm
          type={type}
          selectAddress={selectAddressObject}
          address={objectFields}
        />
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              required
              label="City"
              margin="dense"
              onChange={(e) => onChange("objectAddress", "city_name", e, -1)}
              value={objectFields?.address?.city_name}
              // value={objectFields?.city_name}
              error={!errors.isFilledCity}
            />
            <StyledTextField
              fullWidth
              label="Street"
              margin="dense"
              onChange={(e) => onChange("objectAddress", "street_name", e, -1)}
              value={objectFields?.address?.street_name}
              // value={objectFields?.street_name}
              disabled={unitsLength > 0}
            />
            <StyledTextField
              fullWidth
              label="Number"
              margin="dense"
              onChange={(e) => onChange("objectAddress", "number", e, -1)}
              value={objectFields?.address?.number}
              // value={objectFields?.number}
              disabled={unitsLength > 0}
            />
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Number add"
              margin="dense"
              onChange={(e) => onChange("objectAddress", "number_add", e, -1)}
              value={objectFields?.address?.number_add}
              // value={objectFields?.number_add}
              disabled={unitsLength > 0}
            />
            <StyledTextField
              fullWidth
              label="Postcode"
              margin="dense"
              onChange={(e) => onChange("objectAddress", "postcode", e, -1)}
              value={objectFields?.address?.postcode}
              // value={objectFields?.postcode}
              disabled={unitsLength > 0}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ObjectAddressBlock;
