import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styles from "./PostcodeForm.styles";
import { Box, Divider, FormControl, InputAdornment, Autocomplete } from '@mui/material';
import StyledTextField from "../styled-components/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import InputHelperText from "../styled-components/InputHelperText";
import { receivePostcodeRequest, resetPostcode } from '../../../modules/add-new-entity/actions';
import {createEntity} from '../../../modules/add-new-entity/selectors';
import {initialAddressState} from '../entity-editor/entity-editor-blocks/EntityAddressBlock';


// import {withStyles} from '@material-ui/core/styles';
// import PropTypes from 'prop-types';
// import {INPUT, SELECT} from '../../../constants/field-types';
// import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
// import Search from '@material-ui/icons/Search';
// import Cancel from '@material-ui/icons/CancelOutlined';
// import FieldEditor from '../../editor/field';
// import Container from './Container';
// import PostCodeApiService from '../../../../common/services/postcode-api.service';
// import AlertService from '../../../services/alert.service';
//
// type PostcodeFormProps = {
//   classes: {
//     button: string,
//     buttonGroup: string,
//     divider: string,
//     iconSearch: string,
//     container: string,
//     rightColumn: string,
//     inputWrapper: string,
//   },
// };
//
// type PostcodeFormState = {
//   postcode: string,
//   number: string,
//   addresses: any,
//   currentValue: null | ""
// }

type PostcodeFormProps={
  addressPostcode?: any,
  selectAddress?:any,
  address?: any,
  type?: string,

}

const PostcodeForm = ({addressPostcode, selectAddress, address, type}: PostcodeFormProps) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    let active = true;
    let newOptions: any = [];

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (active) {
      if (value) {
        newOptions = [value];
      }
      if (addressPostcode?.length) {
        newOptions = [...newOptions, ...addressPostcode];
      }
      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }, [dispatch, value, addressPostcode, inputValue]);

  const handleChange =(newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 6 && newInputValue.length <= 12) {
      const requestData = {
        inputValue: newInputValue,
      };
      dispatch(resetPostcode());
      if (value === null) {
        dispatch(receivePostcodeRequest(requestData));
      }
    }
  }
  useEffect(() => {
    if (value && address && type === 'entity') {
      selectAddress({
          ...address,
          // @ts-ignore
          city: value.city,
          // @ts-ignore
          postcode: value.postcode,
          // @ts-ignore
          street_name: value.street,
          // @ts-ignore
          number: value.number,
          // @ts-ignore
          number_add: `${value.letter ? value.letter : ""}${value.addition ? value.addition : ""}`,
        }
      )
      // setValue(null);
      return;
    }
    if (value && address && type === 'object') {

      selectAddress({
        ...address,
        address: {
          ...address.address,
          city_id: address.cityId || "",
          // @ts-ignore
          city_name: value.city|| "",
          // @ts-ignore
          street_name: value.street || "",
          // @ts-ignore
          number: value.number || "",
          // @ts-ignore
          number_add: `${value.letter ? value.letter : ""}${value.addition ? value.addition : ""}` || "",
          // @ts-ignore
          postcode: value.postcode || "",
        },
      })
    }

  }, [value]);

  return (
    <React.Fragment>
      <Box sx={styles.fieldBlock} component="form">
        <FormControl sx={styles.form}  error={!!(inputValue && !value)}>
          <Autocomplete
            id="postcode"
            open={open && inputValue.length >= 6}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            fullWidth
            freeSolo
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event: any, newValue: {}) => {
              // setOptions(newValue ? [newValue, ...options] : options);
              // @ts-ignore
              setValue(newValue);
              // dispatch(resetPostcode());
            }}
            clearOnEscape={true}
            onInputChange={(e, newInputValue) => handleChange(newInputValue)}
            options={options}
            getOptionLabel={(option) => `${option.postcode ? option.postcode : ""} ${option.city ? option.city : ""}, ${option.street ? option.street : ""} ${option.number ? option.number : ""} ${option.letter ? option.letter : ""} ${option.addition ? option.addition : ""}`
            }
            renderInput={(params) => <StyledTextField
              {...params}
              variant="standard"
              margin="dense"
              error={!!(inputValue && !value)}
              InputProps={{
                ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                       <SearchIcon />
                    </InputAdornment>)
            }}

            />}
          />
          <InputHelperText style={{color:"red"}} text="Type postcode and number to search an address" />
        </FormControl>
      </Box>
      <Divider />
    </React.Fragment>
  );
};

//   static propTypes =
//   {
//     classes: PropTypes.object.isRequired,
//   };
//
//   state = {
//     postcode: '',
//     number: '',
//     addresses: [],
//     currentValue: null,
//   };
//
//   getValue = ({city = {}, street, number, addition, letter}) =>
//     //@ts-ignore
//     `${city.label || ''} ${street || ''} ${number || ''} ${addition ||
//     ''}${letter || ''}`.trim();
//
//   handleChange = (type, {target}) => {
//     //@ts-ignore
//     const {onSelect} = this.props;
//     const {addresses} = this.state;
//     if (type === 'addresses') {
//       const {
//         purpose,
//         postcode,
//         city,
//         letter,
//         addition,
//         number,
//         year,
//         street,
//       } = addresses[target.value];
//       onSelect({
//         target: {
//           value: {
//             purpose,
//             postcode,
//             //@ts-ignore
//             city: city.label,
//             number,
//             street_name: street,
//             built_year: year,
//             number_add: `${addition || ''}${letter || ''}`,
//           },
//         },
//       });
//       return this.setState({
//         currentValue: target.value,
//       });
//     }
//     //@ts-ignore
//     this.setState({
//       [type]: target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase(),
//     });
//   };
//
//   handleCancel = () => {
//     this.setState({
//       postcode: '',
//       number: '',
//       addresses: [],
//       currentValue: null,
//     });
//   };
//
//   handleGetPostcodeData = async () => {
//     const {postcode, number} = this.state;
//     await PostCodeApiService.get(
//       {postcode, number},
//       ({data}) => this.setState({addresses: data._embedded.addresses}),
//       e => AlertService.error('No such postcode or something went wrong!', e.message),
//     );
//   };
//
//   render() {
//     //@ts-ignore
//     // const {classes} = this.props;
//     // const {postcode, number, addresses, currentValue} = this.state;
//
//     return (
//       <React.Fragment>
// <div className={classes.container}>
//   <Container
//     leftColumn={[
//       <FieldEditor
//         type={INPUT}
//         name="postcode"
//         label="Postcode"
//         value={postcode}
//         //@ts-ignore
//         onChange={e => this.handleChange('postcode', e)}
//       />,
//     ]}
//     rightColumn={[
//       <div className={classes.rightColumn}>
//         <div className={classes.inputWrapper}>
//           <FieldEditor
//             type={INPUT}
//             name="number"
//             label="Number"
//             value={number}
//             //@ts-ignore
//             onChange={e => this.handleChange('number', e)}
//           />
//         </div>
//         <div className={classes.buttonGroup}>
//           <Button
//             onClik={this.handleGetPostcodeData}
//             className={classes.button}
//           >
//             <Search className={classes.iconSearch}/>
//           </Button>
//           <Button
//             onClick={this.handleCancel}
//             className={classes.button}
//           >
//             <Cancel className={classes.iconSearch}/>
//           </Button>
//         </div>
//       </div>,
//     ]}
//   />
// </div>
// {addresses.length ? (
//   <FieldEditor
//     type={SELECT}
//     name="addresses"
//     label="Addresses"
//     //@ts-ignore
//     onChange={e => this.handleChange('addresses', e)}
//     value={
//       {
//         current: currentValue !== null ? currentValue : '',
//         options: addresses.map((a, index) => ({
//         value: index,
//          label: this.getValue(a),
// })),
//      } || {}
//   }
//  />
// ) : null}
// <Divider className={classes.divider}/>
//       </React.Fragment>
//     );
//   }
// }

function mapStateToProps(state: any) {
  const { addressPostcode } = createEntity(state);
  return { addressPostcode };
}

export default connect(mapStateToProps)(PostcodeForm);
